import _objectSpread from "/var/lib/jenkins/workspace/new-baobao-manage-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
// import adminDashboard from './admin'
// import editorDashboard from './editor'

export default {
  name: 'Dashboard',
  // components: { adminDashboard, editorDashboard },
  data: function data() {
    return {
      currentRole: 'adminDashboard'
    };
  },
  computed: _objectSpread({}, mapGetters(['roles'])),
  created: function created() {
    // if (!this.roles.includes('admin')) {
    //   this.currentRole = 'editorDashboard'
    // }
  }
};