var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "errPage-container" },
    [
      _c(
        "el-button",
        {
          staticClass: "pan-back-btn",
          attrs: { icon: "el-icon-arrow-left" },
          on: { click: _vm.back },
        },
        [_vm._v(" 返回 ")]
      ),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("h1", { staticClass: "text-jumbo text-ginormous" }, [
              _vm._v(" Oops! "),
            ]),
            _vm._v(" gif来源"),
            _c(
              "a",
              { attrs: { href: "https://zh.airbnb.com/", target: "_blank" } },
              [_vm._v("airbnb")]
            ),
            _vm._v(" 页面 "),
            _c("h2", [_vm._v("你没有权限去该页面")]),
            _c("h6", [_vm._v("如有不满请联系你领导")]),
            _c("ul", { staticClass: "list-unstyled" }, [
              _c("li", [_vm._v("或者你可以去:")]),
              _c(
                "li",
                { staticClass: "link-type" },
                [
                  _c("router-link", { attrs: { to: "/dashboard" } }, [
                    _vm._v(" 回首页 "),
                  ]),
                ],
                1
              ),
              _c("li", { staticClass: "link-type" }, [
                _c("a", { attrs: { href: "https://www.taobao.com/" } }, [
                  _vm._v("随便看看"),
                ]),
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.dialogVisible = true
                      },
                    },
                  },
                  [_vm._v("点我看图")]
                ),
              ]),
            ]),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [_vm._v(" 401 ")]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "随便看",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [_c("img", { staticClass: "pan-img", attrs: { src: _vm.ewizardClap } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }