var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-color-picker", {
    staticClass: "theme-picker",
    attrs: {
      predefine: [
        "#1890FF",
        "#F5222D",
        "#FA541C",
        "#FAAD14",
        "#13C2C2",
        "#52C460",
        "#2F54EB",
        "#722ED1",
        "#00b38a",
        "#2878FF",
      ],
      "popper-class": "theme-picker-dropdown",
    },
    model: {
      value: _vm.theme,
      callback: function ($$v) {
        _vm.theme = $$v
      },
      expression: "theme",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }