// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#001529",
	"menuHover": "#000c17",
	"menuLightBg": "#ffffff",
	"menuLightHover": "#f0f1f5",
	"subMenuBg": "#000c17",
	"subMenuHover": "#001528",
	"sideBarWidth": "210px",
	"sidebarTitle": "#ffffff",
	"sidebarLightTitle": "#001529"
};
module.exports = exports;
