var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-container" },
    [_c("el-card", [_vm._v("欢迎登录这里玩直播后台管理系统~")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }