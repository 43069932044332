import "core-js/modules/es.object.to-string.js";
// import { getSetting } from '@/api/login'
import storage from '@/utils/storage';
var state = {
  // info: storage.get('app_info')
  info: {
    sys_app_logo: ''
  }
};
var mutations = {
  SET_INFO: function SET_INFO(state, data) {
    data = {
      sys_app_logo: ''
    };
    state.info = data;
    storage.set('app_info', data);
  }
};
var actions = {
  settingDetail: function settingDetail(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      // getSetting().then(response => {
      //   const { data } = response
      //   commit('SET_INFO', data)
      //   resolve(data)
      // }).catch(error => {
      //   reject(error)
      // })
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};