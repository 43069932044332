import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
import store from '@/store';
import { getToken, removeToken, setToken } from '@/utils/auth';
import Router from '@/router';

// create an axios instance
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 100000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent

  if (store.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['Authorization'] = getToken();
    config.headers['Content-Type'] = 'application/json';
  }
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  // token过期重新设置新的token
  var Authorization = response.headers.authorization;
  if (Authorization) {
    setToken(Authorization);
  }
  var code = response.data.code;
  if (code === 0) {
    if (location.href.indexOf('login') !== -1) {
      Message({
        message: response.data.message,
        type: 'error'
      });
      location.reload(); // 为了重新实例化vue-router对象 避免bug
    } else {
      MessageBox.confirm('权限不足，请联系管理员进行添加', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    }
  } else if (code !== 200001) {
    // Notification.error({
    //   title: response.data.msg
    // })
    // 重新登录
    if (code === 401001) {
      removeToken();
      Router.replace('/');
      localStorage.removeItem('user_info');
    }
    Message({
      message: response.data.message,
      type: 'error'
    });
    return Promise.reject(new Error('error'));
  } else {
    return response.data;
  }
}, function (error) {
  if (error.message === 'Network Error') {
    Message({
      message: '服务器连接异常，请检查服务器！',
      type: 'error',
      duration: 5 * 1000
    });
    return;
  }
  console.log('err', error); // for debug

  Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  // removeToken()
  // Router.replace('/')
  // localStorage.removeItem('user_info')
  return Promise.reject(error);
});
export default service;