import request from '@/utils/request';

// 查询角色列表
export function listRole(query) {
  return request({
    url: '/api/sys/role',
    method: 'get',
    params: query
  });
}
export function getRoutes() {
  return request({
    url: '/api/sys/menu-user-lists',
    method: 'get'
  });
}

// 查询角色详细
export function getRole(roleId) {
  return request({
    url: '/api/sys/role/' + roleId,
    method: 'get'
  });
}

// 新增角色
export function addRole(data) {
  return request({
    url: '/api/sys/role',
    method: 'post',
    data: data
  });
}

// 修改角色
export function updateRole(data, roleId) {
  return request({
    url: '/api/sys/role/' + roleId,
    method: 'put',
    data: data
  });
}

// 角色状态修改
export function changeRoleStatus(roleId, data) {
  return request({
    url: '/api/sys/role/' + roleId,
    method: 'put',
    data: data
  });
}

// 角色数据权限
export function dataScope(data) {
  return request({
    url: '/api/sys/roledatascope',
    method: 'put',
    data: data
  });
}

// 删除角色
export function delRole(data) {
  return request({
    url: '/api/sys/role/' + data,
    method: 'delete'
  });
}
export function getListrole(id) {
  return request({
    url: '/api/v1/menu/role/' + id,
    method: 'get'
  });
}

// export function getMenuNames() {
//   return request({
//     url: '/api/v1/menuids',
//     method: 'get'
//   })
// }