//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _variables from '@/styles/variables.scss';
import setting from '@/settings';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      appInfo: setting
    };
  },
  computed: {
    variables: function variables() {
      return _variables;
    }
  }
};