var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tags-view-container",
      attrs: { id: "tags-view-container" },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-remove": _vm.closeSelectedTag },
          model: {
            value: _vm.editableTabsValue,
            callback: function ($$v) {
              _vm.editableTabsValue = $$v
            },
            expression: "editableTabsValue",
          },
        },
        _vm._l(_vm.visitedViews, function (item) {
          return _c(
            "el-tab-pane",
            {
              key: item.path,
              attrs: {
                closable: item.fullPath === "/dashboard" ? false : true,
                name: item.fullPath,
              },
            },
            [
              _c(
                "router-link",
                {
                  ref: "tag",
                  refInFor: true,
                  staticClass: "tags-view-item",
                  style: {
                    color:
                      item.fullPath === _vm.$route.fullPath ? _vm.theme : "",
                  },
                  attrs: {
                    slot: "label",
                    tag: "span",
                    to: {
                      path: item.path,
                      query: item.query,
                      fullPath: item.fullPath,
                    },
                  },
                  nativeOn: {
                    contextmenu: function ($event) {
                      $event.preventDefault()
                      return _vm.openMenu(item, $event)
                    },
                  },
                  slot: "label",
                },
                [_vm._v(" " + _vm._s(item.title) + " ")]
              ),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          staticClass: "contextmenu",
          style: { left: _vm.left + "px", top: _vm.top + "px" },
        },
        [
          _c(
            "li",
            {
              staticClass: "tags-item",
              on: {
                click: function ($event) {
                  return _vm.refreshSelectedTag(_vm.selectedTag)
                },
                mouseover: function ($event) {
                  return _vm.handleTagsOver(1)
                },
                mouseleave: function ($event) {
                  return _vm.handleTagsLeave(1)
                },
              },
            },
            [_vm._v("刷新当前标签页")]
          ),
          !_vm.isAffix(_vm.selectedTag)
            ? _c(
                "li",
                {
                  staticClass: "tags-item",
                  on: {
                    click: function ($event) {
                      return _vm.closeSelectedTag(_vm.selectedTag)
                    },
                    mouseover: function ($event) {
                      return _vm.handleTagsOver(2)
                    },
                    mouseleave: function ($event) {
                      return _vm.handleTagsLeave(2)
                    },
                  },
                },
                [_vm._v("关闭当前标签页")]
              )
            : _vm._e(),
          _c(
            "li",
            {
              staticClass: "tags-item",
              on: {
                click: _vm.closeOthersTags,
                mouseover: function ($event) {
                  return _vm.handleTagsOver(3)
                },
                mouseleave: function ($event) {
                  return _vm.handleTagsLeave(3)
                },
              },
            },
            [_vm._v("关闭其他标签页")]
          ),
          _c(
            "li",
            {
              staticClass: "tags-item",
              on: {
                click: function ($event) {
                  return _vm.closeAllTags(_vm.selectedTag)
                },
                mouseover: function ($event) {
                  return _vm.handleTagsOver(4)
                },
                mouseleave: function ($event) {
                  return _vm.handleTagsLeave(4)
                },
              },
            },
            [_vm._v("关闭全部标签页")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }