//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import userAvatar from './userAvatar';
import userInfo from './userInfo';
import resetPwd from './resetPwd';
import { getUserProfile } from '@/api/admin/sys-user';
export default {
  name: 'Profile',
  components: {
    userAvatar: userAvatar,
    userInfo: userInfo,
    resetPwd: resetPwd
  },
  data: function data() {
    return {
      user: {},
      roleGroup: {},
      postGroup: {},
      deptGroup: {},
      activeTab: 'userinfo',
      roleIds: undefined,
      postIds: undefined,
      roleName: undefined,
      postName: undefined,
      dept: {},
      deptName: undefined
    };
  },
  created: function created() {
    this.getUser();
  },
  methods: {
    getUser: function getUser() {
      var _this = this;
      getUserProfile().then(function (response) {
        _this.user = response.data.user;
        _this.roleIds = response.data.user.roleIds;
        _this.roleGroup = response.data.roles;
        if (_this.roleIds[0]) {
          for (var key in _this.roleGroup) {
            if (_this.roleIds[0] === _this.roleGroup[key].roleId) {
              _this.roleName = _this.roleGroup[key].roleName;
            }
          }
        } else {
          _this.roleName = '暂无';
        }
        _this.dept = response.data.user.dept;
        _this.deptName = _this.dept.deptName;
      });
    }
  }
};